.cart {
  position: absolute;
  top: -4px;
  right: -4px;
  display: block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  color: white;
}
