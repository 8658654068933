.link {
  margin-bottom: 8px;
  display: block;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
}

.link:last-child {
  margin-bottom: 0;
}
