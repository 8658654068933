.item {
  @apply flex items-center w-full paragraph-s font-semibold hover:bg-blue-5 cursor-pointer;
  gap: 14px;
  padding: 14px 22px;
}

.item:first-child {
  @apply rounded-t-md;
}

.item:last-child {
  @apply rounded-b-md;
}
