@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  font-family: 'Lato', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  display: grid;
  grid-template-rows: auto 1fr auto;
  min-height: 100vh;
}

@layer components {
  .button {
    @apply inline-flex items-center rounded-md px-2.5 py-1 font-semibold text-lg;
    gap: 13px;
    line-height: 24px;
    transition: all 0.2s;
    outline: none;
  }

  .button--block {
    @apply w-full justify-center;
  }

  .button--icon {
    @apply p-1;
  }

  .button--primary {
    @apply bg-blue-100 text-white;
  }

  .button--primary:hover {
    @apply bg-blue-110;
  }

  .button--primary:disabled {
    @apply bg-blue-40;
  }

  .button--secondary {
    @apply bg-blue-10 text-blue-100;
  }

  .button--secondary:hover {
    @apply bg-blue-20 text-blue-110;
  }

  .button--secondary:disabled {
    @apply bg-blue-10 text-blue-40;
  }

  .button--outlined {
    @apply bg-white border border-navy-20 text-navy-80;
  }

  .button--outlined:hover {
    @apply text-navy-5;
  }

  .button--outlined:disabled {
    @apply text-navy-30;
  }

  .button--danger {
    @apply bg-red-10 text-red-100;
  }

  .button--success {
    @apply bg-green-100 text-white;
  }

  .input-label {
    @apply block mb-[6px] leading-24 text-navy-100 text-lg font-semibold;
  }

  .input-helper {
    @apply block mt-[6px] text-navy-60;
  }

  .input {
    @apply block w-full leading-24 py-1 px-[14px] text-lg text-navy-100 bg-white rounded-md border border-navy-20 outline-0 focus:border-blue-90 placeholder:text-navy-60;
  }

  .input:read-only {
    @apply text-navy-60;
  }

  .input[aria-invalid] {
    @apply bg-red-10 border-red-120;
  }

  .input-invalid {
    @apply bg-red-10 border-red-120;
  }

  .flex-center {
    @apply flex items-center justify-center;
  }

  .paragraph-s {
    font-size: 16px;
    line-height: 24px;
  }

  .paragraph-m {
    font-size: 18px;
    line-height: 26px;
  }

  .paragraph-l {
    font-size: 20px;
    line-height: 28px;
  }

  .link {
    @apply text-blue-100 hover:text-blue-80;
  }

  .center {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .nice-table {
    @apply table table-auto w-full text-navy-100 border-separate;
  }

  .nice-table th {
    @apply text-navy-70 font-semibold text-left;
    padding: 12px 28px;
  }

  .nice-table tbody tr {
    background-color: white;
  }

  .nice-table tbody td {
    padding: 20px 28px;
    border-bottom: 1px solid var(--color-navy-20);
  }

  .nice-table tbody td:first-child {
    border-left: 1px solid var(--color-navy-20);
  }

  .nice-table tbody td:last-child {
    border-right: 1px solid var(--color-navy-20);
  }

  .nice-table tbody tr:first-child td {
    border-top: 1px solid var(--color-navy-20);
  }

  .nice-table tbody tr:first-child td:first-child {
    border-top-left-radius: 8px;
  }

  .nice-table tbody tr:first-child td:last-child {
    border-top-right-radius: 8px;
  }

  .nice-table tbody tr:last-child td:first-child {
    border-bottom-left-radius: 8px;
  }

  .nice-table tbody tr:last-child td:last-child {
    border-bottom-right-radius: 8px;
  }
}

.react-datepicker__input-container input {
  @apply input;
  background-image: url('./assets/icons/calendar.svg');
  background-repeat: no-repeat;
  background-size: 20px;
  background-position: right 14px center;
}

.react-datepicker-popper {
  @apply z-40 bg-white p-2 border border-navy-20 shadow-dropdown rounded-md;
}

.react-datepicker-right {
  @apply absolute right-0 left-auto mt-0.5 !important;
}

.react-datepicker__portal {
  @apply absolute z-10 transform-none bg-white shadow p-1 top-12 right-0 border-2 border-navy-20;
}

.react-datepicker__month-container {
  @apply flex flex-col;
}

.react-datepicker__month {
  @apply flex flex-col;
}

.react-datepicker__current-month {
  @apply mb-2 text-lg leading-24 text-navy-80 font-semibold text-center;
}

.react-datepicker__week {
  @apply flex justify-around;
}

.react-datepicker__day-names {
  @apply flex justify-around;
}

.react-datepicker__day-name {
  @apply w-4 h-4 flex-center text-navy-80 leading-20 font-semibold;
}

.react-datepicker__navigation {
  @apply absolute top-2;
}

.react-datepicker__navigation--previous {
  @apply left-2 w-[24px] h-[24px] transition flex items-center justify-center hover:bg-blue-10 rounded-full;
}

.react-datepicker__navigation--next {
  @apply right-2 w-[24px] h-[24px] transition flex items-center justify-center hover:bg-blue-10 rounded-full;
}

.react-datepicker__day {
  @apply w-4 h-4 flex-center leading-20 transition text-navy-80 hover:bg-blue-10 rounded-full cursor-pointer;
}

.react-datepicker__day--disabled {
  @apply cursor-not-allowed text-navy-30;
}

.react-datepicker__day--in-range {
  @apply bg-navy-20;
}

.react-datepicker__day--in-selecting-range {
  @apply bg-blue-20;
}

.react-datepicker__day--selecting-range-start {
  @apply bg-white border-2 border-blue-50;
}

.react-datepicker__day--selecting-range-end {
  @apply bg-white border-2 border-blue-50;
}

.react-datepicker__day--selected {
  @apply bg-blue-100 text-white hover:bg-blue-100;
}

.react-datepicker__day--range-start {
  @apply bg-blue-50 text-white hover:text-navy-70 hover:bg-white;
}

.react-datepicker__day--range-end {
  @apply bg-blue-50 text-white hover:text-navy-70 hover:bg-white;
}
