.price {
  position: relative;
  font-weight: 800;
  font-size: 48px;
  line-height: 60px;
  color: var(--color-navy-100);
}

.price::after {
  position: absolute;
  top: -15px;
  right: -30px;
  content: attr(data-suffix);
  font-size: 24px;
  font-weight: 800;
}

.separator {
  border-color: var(--color-navy-50);
  opacity: 0.3;
}

.label {
  display: block;
  width: 100%;
  background-color: var(--color-blue-100);
  color: white;
  line-height: 44px;
  text-transform: uppercase;
  border-radius: 16px 16px 0 0;
  font-weight: 600;
}

@media (min-width: 768px) {
  .label {
    margin-top: -44px;
  }
}
