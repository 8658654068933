.container {
  font-style: normal;
  font-weight: 700;
}

h1.container {
  font-size: 40px;
  line-height: 48px;
}

h2.container {
  font-size: 36px;
  line-height: 44px;
}

h3.container {
  font-size: 32px;
  line-height: 40px;
}

h4.container {
  font-size: 28px;
  line-height: 36px;
}

h5.container {
  font-size: 22px;
  line-height: 30px;
}

h6.container {
  font-size: 20px;
  line-height: 30px;
}

@media (min-width: 768px) {
  h1.container {
    font-size: 76px;
    line-height: 80px;
  }

  h2.container {
    font-size: 48px;
    line-height: 60px;
  }

  h3.container {
    font-size: 36px;
    line-height: 44px;
  }

  h4.container {
    font-size: 30px;
    line-height: 38px;
  }

  h5.container {
    font-size: 24px;
    line-height: 32px;
  }
}
