.input {
  display: none;
}

.input ~ .checkbox {
  flex: 0 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  border: 1px solid var(--color-navy-20);
  background-color: white;
  border-radius: 50%;
}

.input:checked ~ .checkbox {
  background-color: var(--color-blue-100);
}

.input:checked ~ .checkbox::after {
  content: '';
  background-color: white;
  display: block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
}
