button.remove {
  display: inline-flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 13px;
  padding: 10px 24px;
  border-radius: 8px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  transition: all 0.2s;
  background-color: var(--color-red-10);
  color: var(--color-red-100);
}

.action {
  display: inline;
}

.info {
  display: none;
}

.success {
  cursor: default;
}

@media (min-width: 768px) {
  .success {
    display: none !important;
  }

  button.remove {
    background-color: var(--color-green-100);
    color: white;
  }

  .remove:hover {
    background-color: var(--color-red-10);
    color: var(--color-red-100);
  }

  .info {
    display: inline;
  }

  .action {
    display: none;
  }

  .remove:hover .info {
    display: none;
  }

  .remove:hover .action {
    display: inline;
  }
}
