.container {
  margin-bottom: 8px;
  padding: 8px 14px;
  border-radius: 6px;
  font-weight: 600;
}

.container.danger {
  background-color: var(--color-red-10);
  color: var(--color-red-120);
}

.container.success {
  background-color: var(--color-green-10);
  color: var(--color-green-120);
}

@media (min-width: 768px) {
  .container {
    margin-bottom: 12px;
  }
}
