.shape {
  position: absolute;
  width: 930px;
  height: 930px;
  top: 74px;
  left: 192px;
  opacity: 0.2;
  background-image: url('../assets/images/organic-shape-bg.svg');
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
}
