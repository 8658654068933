.container {
  font-weight: 400;
}

.container.bold {
  font-weight: 600;
}

.container.l {
  font-size: 20px;
  line-height: 28px;
}

.container.m {
  font-size: 18px;
  line-height: 26px;
}

.container.s {
  font-size: 16px;
  line-height: 24px;
}
