.container {
  position: sticky;
  top: 0;
  z-index: 20;
  background-color: white;
  box-shadow: 0px 2px 8px rgba(0, 44, 74, 0.08);
}

.dropdown {
  position: absolute;
  left: 0;
  top: 60px;
  width: 100%;
  background-color: white;
  padding: 32px 20px;
  box-shadow: 0px 2px 8px rgba(0, 44, 74, 0.08);
}
