.step {
  @apply relative flex-1 flex items-center justify-center;
}

.step:not(:last-child)::after {
  @apply content-[''] w-full left-1/2 absolute h-[2px] bg-navy-20;
}

.step.done:not(:last-child)::after {
  @apply bg-blue-100;
}

.indicator {
  @apply relative flex-[0_0_24px] flex items-center justify-center h-2.5 rounded-full z-[1];
}

.indicator.active {
  @apply bg-blue-100 outline outline-4 outline-blue-20 cursor-pointer;
}

.indicator.inactive {
  @apply bg-navy-20 cursor-not-allowed;
}

.indicator.done {
  @apply bg-blue-100;
}
