.container {
  background-image: url('../../assets/images/organic-shape-bg.svg');
  background-repeat: no-repeat;
  background-position: left -100px top 1500px;
  background-size: 425px;
}

.container.right {
  background-size: 455px;
  background-position: right -100px top 1220px;
}

@media (min-width: 768px) {
  .container {
    background-size: 930px;
    background-position: left calc(50% - 400px) top 350px;
  }

  .container.right {
    background-size: 930px;
    background-position: right calc(50% - 400px) top 350px;
  }
}
