.container {
  display: grid;
  gap: 32px;
  grid-template-areas:
    'summary'
    'items';
}

.summary {
  grid-area: summary;
}

.items {
  grid-area: items;
}

@media (min-width: 768px) {
  .container {
    align-items: start;
    grid-template-columns: 2fr 1fr;
    grid-template-areas: 'items summary';
  }
}
