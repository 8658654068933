.input {
  display: none;
}

.input ~ .checkbox {
  flex: 0 0 20px;
  display: inline-block;
  height: 20px;
  border: 1px solid var(--color-navy-20);
  background-color: white;
  border-radius: 4px;
}

.input:checked ~ .checkbox {
  background-color: var(--color-blue-100);
  background-image: url('../../assets/icons/checkbox.svg');
  background-repeat: no-repeat;
  background-position: center;
}
