.control {
  position: absolute;
  top: 0;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 100%;
  -webkit-tap-highlight-color: transparent;
}

.control.back {
  left: 0;
  background: linear-gradient(90deg, #002c4a 0%, rgba(0, 44, 74, 0) 100%);
}

.control.forward {
  right: 0;
  background: linear-gradient(90deg, rgba(0, 44, 74, 0) 0%, #002c4a 100%);
}
