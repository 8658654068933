.container {
  flex: 0 0 146px;
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: var(--color-blue-100);
  text-decoration: none;
}

.icon {
  flex: 0 0 28px;
  height: 28px;
}

@media (min-width: 768px) {
  .container {
    flex: 0 0 165px;
    font-size: 22px;
    line-height: 36px;
  }

  .icon {
    flex: 0 0 36px;
    height: 36px;
  }
}
